import React from "react"
import { Link } from "gatsby"
import Image from "../../components/image"
import Layout from "../../components/layout"
// import SEO from "../components/seo"
import "../../assets/sass/commercial.sass"

import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css'
import ContactForm from '../../components/contactForm'

const Commercial = () => (
<div className="info-page commercial">
    
    <div>
      <h1>Commercial</h1>
      <p>Kevin's landscaping can help your buisness get ready for the new season.</p>
    </div>

    <div class="info-container commercial-page">

        <Carousel>

            <Carousel.Item interval={2500}>
                <img src="https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-23-04.28.49.jpg?alt=media&token=88e66dc7-ccf2-4001-a379-21e8cf5a1026"/>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
                <img src="https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-23-04.29.16.jpg?alt=media&token=3c1fda4a-8e92-4a49-af97-713488ede26c"/>
            </Carousel.Item>
     
        </Carousel>

        <div class="discription">
          <p>Kevin's Commercial landscaping is a service that businesses rely on to ensure a welcoming experience for their customers and employees. We offer more than just lawn care services but provide other services like snow removal and landscape clean up.
          </p>
        </div>

        <Carousel>
            
            <Carousel.Item interval={2500}>
                <Image filename="before-commercial-image.jpg"/>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
                <img src="https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-23-04.23.52.jpg?alt=media&token=45c2a19e-a7a4-46af-950c-39bacc02818a"/>
            </Carousel.Item>
     
        </Carousel>
    </div>
    
    <ContactForm/>
  
  </div>
)

export default Commercial
